:root {
    --background: #eceff1;
    --default-color: #708090;
    --shadow-color: #263238;
    --button-background-color: #708090;
    --button-text-color: #eceff1;
    --button-active-color: #505050;
    --button-disabled-color: #ccc;
    --button-disabled-text-color: #f1f1f1;
    --link-default-color: #666;
    --link-hover-color: #999;
    --link-active-color: red;
    --error-color: red;
    --contrast-background: #fafafa;

    font-size: 14px;
    font-family: -apple-system, 'BlinkMacSystemFont',
        'Segoe UI', 'Roboto',
        'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

@media (prefers-color-scheme: dark) {
    :root {
        --background: #212121;
        --default-color: #fafafa;
        --shadow-color: #616161;
        --button-background-color: #616161;
        --button-text-color: #f1f1f1;
        --button-active-color: #BDBDBD;
        --button-disabled-color: #616161;
        --button-disabled-text-color: #BDBDBD;
        --link-default-color: #f1f1f1;
        --link-hover-color: #fff;
        --link-active-color: #f1f1f1;
        --error-color: #ffaaaa;
        --contrast-background: #000000;
    }
}

body {
    margin: 0;
    user-select: none;
    color: var(--default-color);
    background: var(--background);
}

h1 {
    margin: 10px 0 0 0;
}

h2 {
    margin: 10px 0;
}

h3 {
    margin: 10px 0;
}

button {
    border: none;
    background: var(--button-background-color);
    color: var(--button-text-color);
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 3px;
    outline: none;
}

button:active {
    background: var(--button-active-color);
}

button:disabled {
    background: var(--button-disabled-color);
    color: var(--button-disabled-text-color);
}

a:link, a:visited {
    color: var(--link-default-color);
}

a:hover {
    color: var(--link-hover-color);
}

a:active {
    color: var(--link-active-color);
}

.wrapper {
    text-align: center;
}

.game {
    display: inline-block;
    text-align: left;
}

.map-params {
    margin: 10px 0;
    overflow: hidden;
}

.map {
    background: var(--contrast-background);
}

.help-button {
    float: right;
}

.map-params__item + .map-params__item {
    margin-top: 5px;
}

.controls {
    margin-top: 10px;
}

.delimiter {
    margin: 0 5px;
}

.delimiter:after {
    content: '|';
}

.messages-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.messages-container .message {
    position: relative;
    width: 200px;
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid var(--default-color);
    color: var(--default-color);
    border-radius: 5px;
    background: var(--background);
}

.messages-container .close {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 20px;
    cursor: pointer;
}

.messages-container .error {
    color: var(--error-color);
    border-color: var(--error-color);
}

.classical-version-link {
    display: none;
}

.help {
    --width: 600px;
    --height: 420px;

    cursor: initial;
    user-select: text;

    width: var(--width);
    height: var(--height);

    position: absolute;
    top: calc((100vh - var(--height)) / 2);
    left: calc((100vw - var(--width)) / 2);

    padding: 5px 10px;

    background: var(--background);
    border: 1px solid var(--default-color);
    border-radius: 10px;
    box-shadow: var(--shadow-color) 2px 2px 2px;
}

.help_hidden {
    display: none;
}

.help__close {
    position: absolute;
    top: 10px;
    right: 10px;
}
